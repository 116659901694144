const dotenv = require('dotenv');
var path = require('path');
// dotenv.config();
// dotenv.config({ path: path.resolve(__dirname, '../openshift/rcs-cicd/rcs-cloud.env') });
// dotenv.config({ path: path.resolve(__dirname, '../openshift/rcs-cicd/rcs-cloud.secrets') });
dotenv.config({ path: path.resolve(__dirname, '../conf/properties.env') });
const pjson = require('./package.json')


console.log(process.env.API_INTEGRATION);
module.exports = {

	// Node Config

	node: {

		port: process.env.OPENSHIFT_NODEJS_PORT || 9090,
		host: process.env.OPENSHIFT_NODEJS_IP || '0.0.0.0'

	},

	// JWT Config
	jwt: {

		jwtSecret: process.env.JWT_SECRET || '@mura2020',
		jwtExpired: process.env.JWT_EXPIRED || '1d'

	},

	// API config	
	api: {
		LEGACY_VALUE: process.env.LEGACY_VALUE || "OFF",
		API_INTEGRATION: process.env.API_INTEGRATION || 'https://rmxd-integration-rmxd-qa.ext.ocp.qa.lafargeholcim-go.com/', //'http://localhost:8080/' //'https://rmxd-integration-rmxd-dev.ext.ocp.dev.lafargeholcim-go.com/'
		CIAM_API_KEY: process.env.CIAM_API_KEY || '4_7CS08MVFjS4JY3R8Gr2N2A',
	},

	general: {
		version: pjson.version || '0',
		enviroment: process.env.ENVIROMENT || ''
	}

}


